import MainService from "@/services/MainService.js";
export default {
    name: "PrincipalPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ManualPage",
            ss: ss,

            auth:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

        
        };
        
    },
    methods: {
     
          
          
          
          
    },
    
    components: {
        
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        }
        // else{
        // }
    }
};
