import MainService from "@/services/MainService.js";
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "PrincipalPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PrincipalPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            cadm: {},
            cop: {},
            options : {
                series: [44, 55, 13, 43, 22],
                chart: {
                width: 380,
                type: 'pie',
              },

              
              labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
              },
        
            totaltipoins:[],
            labelstotal:[],
            auth:{},
            chartOptions: {
                labels: [],	
            },

            totalUA:[],
            labelstotalUA:[],
            chartOptionsUA: {
              labels: [],	
            },
                        
        };
        
    },
    methods: {
        getRolAdm() {
            this.ss.getRolAdm().then(
                (result) => {
                    let response = result.data;
                    this.cadm = response.countAdm;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getRolOp() {
            this.ss.getRolOp().then(
                (result) => {
                    let response = result.data;
                    this.cop = response.countOp;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPorTipoIns() {
            this.ss.listPorTipoIns().then(
              (result) => {
                let response = result.data;
                this.totaltipoins = response.total;
                this.labelstotal = response.tipos;
          
                // Add labelstotal data to labels array
                for (let label of this.labelstotal) {
                  this.chartOptions.labels.push(label);
                }
              }
            ).catch(error => {
              console.log(error);
            });
          },


          getPorUA() {
            this.ss.listPorUA().then(
              (result) => {
                let response = result.data;
                console.log(response);

                this.totalUA = response.total;
                this.labelstotalUA = response.unidades;
          
                // Add labelstotal data to labels array
                for (let label of this.labelstotalUA) {
                  this.chartOptionsUA.labels.push(label);
                }
              }
            ).catch(error => {
              console.log(error);
            });
          },
          
          
          
          
    },
    
    components: {
        VueApexCharts
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getRolAdm();
            this.getRolOp();
            this.getPorTipoIns();
            this.getPorUA();
        }
    }
};
