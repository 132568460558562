var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }
    getRolAdm() {
        return axios.get('api/Persona/getRolAdm');
    }
    getRolOp() {
        return axios.get('api/Persona/getRolOp');
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }




    //Pais
    showPais(id) {
        return axios.get('api/Pais/show?id=' + id);
    }

    indexPais() {
        return axios.defaults.baseURL + 'api/Pais/index';
    }

    listPais() {
        return axios.get('api/Pais/list');
    }

    storePais(item) {
        return axios.post('api/Pais/store', item);
    }

    destroyPais(item) {
        return axios.post('api/Pais/destroy', item);
    }



    //Ciudad
    showCiudad(id) {
        return axios.get('api/Ciudad/show?id=' + id);
    }

    indexCiudad() {
        return axios.defaults.baseURL + 'api/Ciudad/index';
    }

    listCiudad() {
        return axios.get('api/Ciudad/list');
    }

    list2Ciudad(id) {
        return axios.get('api/Ciudad/list2?Pais=' + id);
    }

    storeCiudad(item) {
        return axios.post('api/Ciudad/store', item);
    }

    destroyCiudad(item) {
        return axios.post('api/Ciudad/destroy', item);
    }



    //TipoConvenio
    showTipoConvenio(id) {
        return axios.get('api/TipoConvenio/show?id=' + id);
    }

    indexTipoConvenio() {
        return axios.defaults.baseURL + 'api/TipoConvenio/index';
    }

    listTipoConvenio() {
        return axios.get('api/TipoConvenio/list');
    }

    

    storeTipoConvenio(item) {
        return axios.post('api/TipoConvenio/store', item);
    }

    destroyTipoConvenio(item) {
        return axios.post('api/TipoConvenio/destroy', item);
    }



        //Institucion
        showInstitucion(id) {
            return axios.get('api/Institucion/show?id=' + id);
        }
    
        indexInstitucion() {
            return axios.defaults.baseURL + 'api/Institucion/index';
        }
    
        listInstitucion() {
            return axios.get('api/Institucion/list');
        }
    
        storeInstitucion(item) {
            return axios.post('api/Institucion/store', item);
        }
    
        destroyInstitucion(item) {
            return axios.post('api/Institucion/destroy', item);
        }


         //Institucion
         showConvenio(id) {
            return axios.get('api/Convenio/show?id=' + id);
        }
    
        indexConvenio() {
            return axios.defaults.baseURL + 'api/Convenio/index';
        }
    
        listConvenio() {
            return axios.get('api/Convenio/list');
        }

        getCInst() {
            return axios.get('api/Convenio/getCInst');
        }
    
        storeConvenio(item) {
            return axios.post('api/Convenio/store', item);
        }
    
        destroyConvenio(item) {
            return axios.post('api/Convenio/destroy', item);
        }



         //TipoInstitucion
    showTipoInstitucion(id) {
        return axios.get('api/TipoInstitucion/show?id=' + id);
    }

    indexTipoInstitucion() {
        return axios.defaults.baseURL + 'api/TipoInstitucion/index';
    }

    listTipoInstitucion() {
        return axios.get('api/TipoInstitucion/list');
    }

    storeTipoInstitucion(item) {
        return axios.post('api/TipoInstitucion/store', item);
    }

    destroyTipoInstitucion(item) {
        return axios.post('api/TipoInstitucion/destroy', item);
    }


    listPorUA() {
        return axios.get('api/Institucion/getPorUA');
    }

    listPorTipoIns() {
        return axios.get('api/Institucion/getPorTipoIns');
    }


    getexcel() {
        return axios.get('api/Convenio/get_excel');
    }


    buscarSaga(params) {
        return axios.get('api/Persona/buscarSaga', {params: params});
    }

    indexInfoConvenio() {
        return axios.defaults.baseURL + 'api/Convenio/indexInfo';
    }


    showInfoConvenio(id) {
        return axios.get('api/Convenio/showInfo?id=' + id);
    }

    listUnidadAcademicados() {
        return axios.get('api/UnidadAcademica/listdos');
    }

    
}