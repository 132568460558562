<template>
    <v-list nav dense >

        <v-list-group  >
            <template v-slot:activator > 
                <v-list-item-icon >
                    <v-icon class="text-white">mdi-decagram-outline</v-icon>

                </v-list-item-icon>
                <v-list-item-content >
                    <v-list-item-title class="text-white">Configuración</v-list-item-title>
                </v-list-item-content>
            </template>
            
            <div class="ml-5">
             <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


              <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Pais"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-globe</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("País")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Ciudad"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-asterisk</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("Ciudad")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="TipoConvenio"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-certificate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("Tipo de Convenio")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


            <router-link
                class="black--text text--accent-4"
                to="TipoInstitucion"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-certificate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">Tipo Institucion</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            </div>

        </v-list-group>

        <v-list-item-group>
            <router-link
                class="black--text text--accent-4"
                to="/"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("Inicio")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <!-- <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


            <!-- <router-link
                class="black--text text--accent-4"
                to="Pais"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-globe</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("País")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->


            <!-- <router-link
                class="black--text text--accent-4"
                to="Ciudad"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-asterisk</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("Ciudad")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->


            <!-- <router-link
                class="black--text text--accent-4"
                to="TipoConvenio"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-certificate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("Tipo de Convenio")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

             <!-- <router-link
                class="black--text text--accent-4"
                to="TipoInstitucion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-certificate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">Tipo Institucion</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->



            <!-- <router-link
                class="black--text text--accent-4"
                to="Institucion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">fa fa-university</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">{{
                            $t("Institución")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->


            <router-link
                class="black--text text--accent-4"
                to="Convenio"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-note-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">Registro Convenios</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Reportes"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-note</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">Reportes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


            <router-link
                class="black--text text--accent-4"
                to="Manual"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="text-white">mdi-eye</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-white">Manual</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link
                class="black--text text--accent-4"
                to="TipoReporte"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-note-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporte")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            


            <!-- <router-link
                class="black--text text--accent-4"
                to="TipoReporteVer"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-chart</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporteVer")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona:{}

        };
    },
     mounted() {
        this.persona = JSON.parse(localStorage.getItem("persona"));
    },
};
</script>
